import * as React from 'react'
import './CollectionSlider.scss'
import Deal from '../../models/deal'
import Collection from '../../models/collection'
import bind from 'bind-decorator'
import { Grid } from '../../components/grid'
import { Link } from 'react-router-dom'
import CardWithData from './CardWithData'
import { shuffle, chunk } from 'lodash'
import { CollectionBannerProps } from '../../models/collectionBanner'
import CollectionBanner from '../../components/home-collection-banner/CollectionBanner'

type MethodProps = {
  sliderScrollHandler: (collection: Collection) => void
}

export type FieldProps = {
  collection?: Collection
  deals?: Deal[]
  categoryName?: string
  categoryLink?: string
  shuffle?: boolean
  collectionBanner?: CollectionBannerProps
}
export type Props = FieldProps & MethodProps

interface State {
  shuffled: boolean
  deals: Deal[] | undefined
}

class CollectionSlider extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  lastScrollX: number = 0

  constructor(props: Props) {
    super(props)

    this.state = {
      shuffled: false,
      deals: this.props.deals,
    }
  }

  splitSlider(deals: Deal[]) {
    // Change the Number to set slider max limit
    const MAX_SLIDER_DEALS_NUMBER = 16
    return chunk(deals, MAX_SLIDER_DEALS_NUMBER)
  }

  componentDidUpdate() {
    if (this.props.deals && this.props.deals.length > 0 && !this.state.shuffled) {
      let deals = this.props.deals

      if (this.props.shuffle) {
        deals = shuffle(deals)
      }

      this.setState({
        deals,
        shuffled: true,
      })
    }
  }

  @bind
  onScroll(event: React.TouchEvent) {
    if (this.props.collection === undefined) {
      return
    }
    if (event.currentTarget.scrollLeft !== undefined && event.currentTarget.scrollLeft !== this.lastScrollX) {
      this.lastScrollX = event.currentTarget.scrollLeft
      this.props.sliderScrollHandler(this.props.collection)
    }
  }

  render() {
    const { collection, categoryName, categoryLink, collectionBanner } = this.props

    const { deals } = this.state

    let title: string | undefined = ''

    if (process.env.REACT_APP_REGION === 'NZ') {
      if (collection !== undefined) {
        title = collection.title ? collection.title : ''
        if (title !== undefined) {
          title = title.replace('Homepage Gold - ', '')
          title = title.replace('Homepage Gold ', '')
          title = title.replace('Homepage Gold', '')
        }
      }
    }

    if (collection === undefined || deals === undefined) {
      return null
    }

    return (
      <Grid>
        {categoryName && (
          <div className='row deal-collection-title'>
            <div className='col-xs-12'>
              {categoryLink !== undefined && (
                <Link
                  to={categoryLink}
                  className='deal-collection-title__link'
                >
                  <h2 className='deal-collection-title__title'>{categoryName}</h2>
                </Link>
              )}
              {categoryLink === undefined && <h2>{categoryName}</h2>}
            </div>
          </div>
        )}
        {!categoryName && title && process.env.REACT_APP_REGION === 'NZ' && (
          <div className='row deal-collection-title'>
            <div className='col-xs-12'>
              <h2>{title}</h2>
            </div>
          </div>
        )}
        {collectionBanner && collectionBanner?.desktopImage && (
          <div className='row collection-banner'>
            <div className='col-xs-12'>
              <CollectionBanner {...collectionBanner} />
            </div>
          </div>
        )}

        {this.splitSlider(deals).map((items, index) => (
          <div
            className='deal-slider row'
            onTouchEnd={this.onScroll}
            key={index}
          >
            {items.map(deal => (
              <div
                className='col-xs-3 col-md-4 col-lg-3 deal-slider__slide'
                key={deal.slug}
              >
                <CardWithData
                  slug={deal.slug}
                  location='home'
                  type='gold'
                />
              </div>
            ))}
          </div>
        ))}
      </Grid>
    )
  }
}

export default CollectionSlider
